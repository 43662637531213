import { useEffect, useState } from 'react';
import Loading from '../loading';

interface Props {
  list: Array<{ activatePhone: string; checkCode: string }>;
  value?: { activatePhone: string; checkCode: string };
  selectPhoneFlag?: boolean;
  onChange: (v: { activatePhone: string; checkCode: string }) => void;
  onClose: () => void;
  onChangeNextPage: () => void;
}

export default function SelectPhoneDialog({
  list,
  value,
  selectPhoneFlag,
  onChange,
  onClose,
  onChangeNextPage,
}: Props) {
  const [page, setPage] = useState(0);
  const [showList, setShowList] = useState<Array<{ activatePhone: string; checkCode: string }>>([]);
  const [select, setSelect] = useState(value);
  const [loadingShow, setLoadingShow] = useState(true);

  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
    if (list.length === 0) {
      setLoadingShow(true);
    } else {
      setLoadingShow(false);
    }
    setShowList(list.slice(0, 10));
  }, [list]); // eslint-disable-line

  function handleSwitchPage() {
    let _page = page + 1;
    if (Math.floor(list.length / 10) - 1 < _page) {
      // 需要重新请求
      onChangeNextPage();
      setLoadingShow(true);
      _page = 0;
    }
    setPage(_page);

    setShowList(list.slice(_page * 10, (_page + 1) * 10));
  }

  function handleClose() {
    window.document.body.style.overflow = '';
    onClose();
  }

  function handleConfirm() {
    if (list.length === 0) {
      onClose();
    } else if (select) {
      onChange(select);
      onClose();
    }
  }

  return (
    <div className="select-phone-dialog">
      <div className="mask" onClick={handleClose} />
      <div className="content">
        <div className="title-block">
          <div className="title">在线选号</div>
          <div className="close" onClick={handleClose}>
            <img alt="" className="close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="card-content">
          {showList.map((i, idx) => (
            <div
              key={i.activatePhone + '' + idx}
              className={`card-item ${i.activatePhone === select?.activatePhone ? 'active' : ''}`}
              onClick={() => setSelect(i)}
            >
              {i.activatePhone}
              <img className="icon-select" src="/pic/icon_select.png" alt="" />
            </div>
          ))}
        </div>
        <div className="card-btn-block">
          {list?.length >= 10 && (
            <div className="card-btn switch-btn" onClick={handleSwitchPage}>
              <img className="refresh-icon" src="/pic/icon_refresh.png" alt="" />
              <div>换一换</div>
            </div>
          )}
          <div className="card-btn card-confirm-btn flex-1" onClick={handleConfirm}>
            <div>确认</div>
            {select && <div className="card-select">已选“{select.activatePhone}”</div>}
          </div>
        </div>
        {loadingShow && (
          <Loading
            childrenClass={!selectPhoneFlag ? 'loading-png' : ''}
            pic={!selectPhoneFlag ? '/pic/pic_page_status_nodata.png' : ''}
            tips={!selectPhoneFlag ? '暂无可选号码' : '正在加载中...'}
          />
        )}
      </div>
    </div>
  );
}

let closeIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACSUlEQVR4nO2avXKbQBSF9Th6CBUpXbpwYcfyT+L8PIr2nLPvlDKly8SWnThOYj+AyxRKEcggBliBgAXmfjOUcPd8u8Cyy2xmGIZhGIZhGIZh1Md7P3fOLfqu65xbeO/nfdfdwns/J/kM4KVPCc65BYAXks/RJKxWq1ckP5HcJMc3ABdd13XOHZP8kqn7meRB13W3KAj/XwLJ867qFoSPIyEzBPMN2XR1O1TVJPkHwGHbNSsBcJH0eFGD7gCctVWrouc3AJ4kfWyrVi1InpO8L5MgablvjarwJH+T/NBGlsZUSZC03kfC4MOneO/PSN6VDNE1gNO616wKL+mXpPddZGkMgFIJJG/rSAj0/PDCp0haSlqXSZD0OnSNQPifJN/1kaUxkpYACiVIunHOlUoYffgUAKckb0ueCTckT/LnBO75R0lXMbI0pkoCya9ZCYGefwQwrvApu8wYd5jhHcXOsRchCZMOn0LyJBn2RUGLjgdJb2K3u1UkHe8o4YHkZez2dkIioexhtyH5fbLhZ7Pg037aAnYIP10JNcJPT0LoPZ8cpfOE2O3fi10mOQCOJikhMOy3XnUkL5NhP43bIRD+R9EkZzISQuFJvi07d/QSQl91VeFTRish9D1f55N2dBJCKzlNFjNGIyG0gMk9lrEGL6HL8CmDlRDatGhz6XpwEmIsYw1qxkjygP+2pPMNeWKH21UVI+G6993hvARJnYbP1M1L6D98SvqLTFfDvozM7RDvF5kU7/08Rg8AOIwe3jAMwzAMwzAMY6z8BS3ngNlcif7iAAAAAElFTkSuQmCC';
