import React from 'react';
import ReactDOM from 'react-dom/client';

export function isWeixinEnv() {
  let ua = window.navigator.userAgent.toLowerCase();

  return ua.includes('micromessenger');
}

export function rpx(t: number): number {
  return (t / 375) * window.innerWidth;
}

// 延迟执行
export function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function BottomBox({ content }: { content: React.ReactNode }) {
  return (
    <div className="bottom-box">
      <div className="flex-1"></div>
      <div className="content bw-100">{content}</div>
    </div>
  );
}

export function showBottom(buildContent: (onOk: (value: unknown) => void) => React.ReactNode): Promise<any> {
  return new Promise((resolve) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const root = ReactDOM.createRoot(div);
    const onOk = (v: any) => {
      document.body.removeChild(div);
      resolve(v);
    };
    root.render(<BottomBox content={buildContent(onOk)} />);
  });
}

interface ConfirmBoxProps {
  title?: string;
  content: React.ReactNode;
  hideConfirmBtn?: boolean;
  hideCancelBtn?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

function ConfirmBox({
  title,
  content,
  hideConfirmBtn,
  hideCancelBtn,
  confirmBtnText,
  cancelBtnText,
  onConfirm,
  onCancel,
}: ConfirmBoxProps) {
  return (
    <div className="confirm-box">
      <div className="content">
        <div className="p-10 font-widget-600 font-size-16">{title ?? '提示'}</div>
        <div className="text">{content}</div>
        <div className="d-flex-r border-top bw-100">
          {!hideCancelBtn && (
            <div className="flex-1 p-10 text-center border-right" onClick={onCancel}>
              {cancelBtnText ?? '取消'}
            </div>
          )}
          {!hideConfirmBtn && (
            <div className="flex-1 p-10 text-center text-color-p" onClick={onConfirm}>
              {confirmBtnText ?? '确定'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export async function showConfirm({
  title,
  content,
  hideConfirmBtn,
  hideCancelBtn,
  confirmBtnText,
  cancelBtnText,
}: ConfirmBoxProps): Promise<any> {
  return new Promise((resolve) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const root = ReactDOM.createRoot(div);
    const onOk = (v: any) => {
      document.body.removeChild(div);
      resolve(v);
    };
    root.render(
      <ConfirmBox
        title={title}
        content={content}
        hideConfirmBtn={hideConfirmBtn}
        hideCancelBtn={hideCancelBtn}
        confirmBtnText={confirmBtnText}
        cancelBtnText={cancelBtnText}
        onConfirm={() => onOk(1)}
        onCancel={() => onOk(0)}
      />
    );
  });
}

interface ToastBoxProps {
  msg: string;
}

function ToastBox({ msg }: ToastBoxProps) {
  return (
    <div className="toast-box">
      <div className={`toast`}>{msg}</div>
    </div>
  );
}
type ToastClose = () => void;
export function showToast(msg: string): ToastClose {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  root.render(<ToastBox msg={msg} />);
  let ref: { root?: ReactDOM.Root; div: HTMLDivElement } = { root, div };

  setTimeout(() => {
    if (ref.root) {
      ref.root.unmount();
      document.body.removeChild(ref.div);
      ref.root = undefined;
    }
  }, 2000);
  return () => {
    if (ref.root) {
      ref.root.unmount();
      document.body.removeChild(ref.div);
      ref.root = undefined;
    }
  };
}
// 解析url参数
export function parseLocationParams(params?: string): { [x: string]: string } {
  if (!params) return {};
  let real = params.startsWith('?') ? params.split('?')[1] : params;
  let _tmpList = real.split('&') || [];
  let p: { [x: string]: string } = {};
  for (let _tmp of _tmpList) {
    let [_k, _v] = _tmp.split('=');
    p[_k] = _v;
  }
  return p;
}

export async function copyToClipboard(text: string, onSuccess?: () => void) {
  if (!text) {
    return showToast('内容为空,不可复制');
  }
  let div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  root.render(
    <div className={`copy-to-clipboard`}>
      <textarea id="copyToClipboard" defaultValue={text} className="text-area"></textarea>
    </div>
  );
  await delay(200);
  let ta: HTMLTextAreaElement | undefined = document.getElementById('copyToClipboard') as HTMLTextAreaElement;
  if (ta) {
    ta.select();
    let res = document.execCommand('copy');
    if (res) {
      if (onSuccess) {
        onSuccess();
      } else {
        showToast('复制成功');
      }
    }
  }
  await delay(500);
  root.unmount();
  document.body.removeChild(div);
}
