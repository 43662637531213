import React, { useEffect, useState } from 'react';
import Page from '../components/page';
import AppBar from '../components/appBar';
import main from '../main';
import MCityPicker from '../components/m_city_picker/m_city_picker';
import MCheckbox from '../components/radioBox';
import ProtocolDialog from '../components/protocolDialog';
import SelectPhoneDialog from '../components/selectPhoneDialog';
import Empty from '../components/empty';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseLocationParams, showToast } from '../utils/utils';
import { Goods } from '../types';
import { apiGetCardPool, apiGetGoodsDetail, apiMonitorPage, apiSubmitOrder } from '../api';

export default function TrainDetailPage() {
  let location = useLocation();
  const navigate = useNavigate();
  const [goodsDetail, setGoodsDetail] = useState<Goods>();
  const [loadError, setLoadError] = useState('');
  // 已选卡号
  const [selectCard, setSelectCard] = useState<{ activatePhone: string; checkCode: string }>();
  // 身份证名
  const [cardName, setCardName] = useState('');
  // 身份证号
  const [cardNum, setCardNum] = useState('');
  // 收件人手机号
  const [phone, setPhone] = useState('');
  // 省市区
  const [province, setProvince] = useState('');
  // 市区
  const [city, setCity] = useState('');
  // 区
  const [area, setArea] = useState('');
  // 地址
  const [address, setAddress] = useState('');
  // 身份证头像照片
  const [cardAvatar, setCardAvatar] = useState<string>();
  // 身份证国徽照片
  const [cardNationalEmblem, setNationalEmblem] = useState<string>();
  // 手持身份证照片
  const [handPhoto, setHandPhoto] = useState<string>();
  // 已选同意协议
  const [checkProtocol, setCheckProtocl] = useState(false);
  // 显示协议弹窗
  const [showProtocol, setShowProtocol] = useState<string>();
  // 显示选择卡号弹窗
  const [showSelectCardDialog, setShowSelectCardDialog] = useState(false);
  const [cardPool, setCardPool] = useState([]);
  useEffect(() => {
    let { code } = parseLocationParams(location.search);
    if (code) {
      loadGoodsDetail(code);
    }
  }, []); // eslint-disable-line

  async function loadGoodsDetail(code: string) {
    try {
      let res = await apiGetGoodsDetail(code);
      let data = res.data;
      if (data.code === 200) {
        setGoodsDetail(data.data);
        if (data.data.ifCampOn === 'Y') {
          loadCardPool(data.data.goodsCode);
        }
        if (data.data.id) {
          minitorPage(data.data.id);
        }
      } else {
        setLoadError(data.msg ?? '暂未找到商品');
      }
    } catch (e: any) {
      setLoadError(e.message ?? '暂未找到商品');
    }
  }
  async function minitorPage(id: string) {
    try {
      await apiMonitorPage(id);
    } catch (e) {}
  }

  async function loadCardPool(code: string) {
    try {
      let res = await apiGetCardPool(code);
      let data = res.data;
      if (data.code === 200) {
        setCardPool(data.data);
      } else {
        showToast(data.msg ?? '获取可选号码失败,请重试');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取可选号码失败,请重试');
    }
  }

  function handleCityPickerChange(v: string[]) {
    setProvince(v[0]);
    setCity(v[1]);
    setArea(v[2]);
  }

  async function handleSubmit() {
    if (goodsDetail?.ifCampOn === 'Y' && !selectCard) {
      return showToast('请选择号码');
    }
    if (!cardName) {
      return showToast('请输入身份证名');
    }
    if (!cardNum) {
      return showToast('请输入身份证号');
    }
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(phone)) {
      return showToast('请输入正确的收件号码');
    }
    if (!province) {
      return showToast('请选择省市区');
    }
    if (!address) {
      return showToast('请输入详细地址');
    }
    if (goodsDetail?.ifPhoto === 'Y' && !cardAvatar) {
      return showToast('请上传身份证头像照片');
    }
    if (goodsDetail?.ifPhoto === 'Y' && !cardNationalEmblem) {
      return showToast('请上传身份证国徽照片');
    }
    if (goodsDetail?.ifPhoto === 'Y' && !handPhoto) {
      return showToast('请上传手持身份证照片');
    }
    if (!checkProtocol) {
      return showToast('请阅读并同意协议');
    }
    try {
      let res = await apiSubmitOrder({
        goodsId: goodsDetail!.id,
        cardName,
        cardNo: cardNum,
        receiverName: cardName,
        receiverPhone: phone,
        province,
        city,
        county: area,
        address,
        activatePhone: selectCard,
        avatarImg: cardAvatar,
        nationalEmblemImg: cardNationalEmblem,
        handPhotoImg: handPhoto,
      });
      let data = res.data;
      if (data.code === 200) {
        showToast('提交成功');
      } else {
        showToast(data.msg ?? '提交失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '提交失败');
    } finally {
      setSelectCard(undefined);
      setCardName('');
      setCardNum('');
      setPhone('');
      setAddress('');
      setCardAvatar('');
      setNationalEmblem('');
      setHandPhoto('');
    }
  }

  if (loadError || !goodsDetail) {
    return (
      <Page
        style={main.pageBgStyle}
        className="detail-page d-flex-c"
        contentClassName="flex-1 h-1 overflow-y"
        appBar={<AppBar className="bg-transparent" title="商品详情" />}
      >
        <Empty tips={loadError || '暂无商品'} />
      </Page>
    );
  }

  return (
    <Page
      style={main.pageBgStyle}
      className="detail-page d-flex-c"
      contentClassName="flex-1 h-1 d-flex-c"
      appBar={<AppBar showBack={false} onBack={() => navigate('/')} className="bg-transparent" title="商品详情" />}
    >
      <div className="flex-1 h-1 overflow-y">
        <img
          className="head-img overflow-hidden border-radius-10 m-10 box-shadow"
          src={goodsDetail!.goodsHeadImg}
          alt=""
        />
        <div className="form-block m-10 border-radius-10 bg-white p-10 box-shadow">
          <div className="font-size-18 font-widget-600 text-center mb-10">{goodsDetail!.goodsName}</div>
          {goodsDetail!.ifCampOn === 'Y' && (
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">在线选号</div>
              <div
                className={`value border-bottom flex-1 ${selectCard ? '' : 'text-color-9'}`}
                onClick={() => setShowSelectCardDialog(true)}
              >
                {selectCard?.activatePhone || '请选择号码'}
              </div>
            </div>
          )}
          <div className="prop-line mb-10 d-flex-r ai-center">
            <div className="label">机主姓名</div>
            <div className="value border-bottom flex-1">
              <input
                className="input"
                placeholder="请输入机主姓名"
                value={cardName}
                onChange={(e) => setCardName(e.target.value)}
              />
            </div>
          </div>
          <div className="prop-line mb-10 d-flex-r ai-center">
            <div className="label">身份证号</div>
            <div className="value border-bottom flex-1">
              <input
                className="input"
                placeholder="请输入身份证号"
                value={cardNum}
                onChange={(e) => setCardNum(e.target.value)}
              />
            </div>
          </div>
          <div className="prop-line mb-10 d-flex-r ai-center">
            <div className="label">收件号码</div>
            <div className="value border-bottom flex-1">
              <input
                className="input"
                placeholder="请输入收件号码"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="prop-line mb-10 d-flex-r ai-center">
            <div className="label">城市选择</div>
            <div className="value border-bottom flex-1">
              <MCityPicker onChange={handleCityPickerChange} someProvince={[]} />
            </div>
          </div>
          <div className="prop-line mb-5 d-flex-r ai-center">
            <div className="label">详细地址</div>
            <div className="value border-bottom flex-1">
              <input
                className="input"
                placeholder="请输入详细地址"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          {goodsDetail!.ifPhoto === 'Y' && (
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">身份证头像面</div>
              <div className="value border-bottom flex-1 pic pb-5 d-flex-r">
                {cardAvatar ? (
                  <div
                    className="upload-pic border-radius-4 overflow-hidden mr-5"
                    onClick={() => setCardAvatar(undefined)}
                  >
                    <img className="bw-100 bh-100" src={cardAvatar} alt="" />
                    <div className="icon-close-block">
                      <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                    </div>
                  </div>
                ) : (
                  <div className="upload-pic border border-radius-4 text-center">
                    <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                    <input className="upload-input bw-100 bh-100" type="file" accept=".jpg,.png,.jpeg" />
                  </div>
                )}
              </div>
            </div>
          )}
          {goodsDetail!.ifPhoto === 'Y' && (
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">身份证国徽面</div>
              <div className="value border-bottom flex-1 pic pb-5 d-flex-r">
                {cardNationalEmblem ? (
                  <div
                    className="upload-pic border-radius-4 overflow-hidden mr-5"
                    onClick={() => setNationalEmblem(undefined)}
                  >
                    <img className="bw-100 bh-100" src={cardNationalEmblem} alt="" />
                    <div className="icon-close-block">
                      <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                    </div>
                  </div>
                ) : (
                  <div className="upload-pic border border-radius-4 text-center">
                    <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                    <input className="upload-input bw-100 bh-100" type="file" accept=".jpg,.png,.jpeg" />
                  </div>
                )}
              </div>
            </div>
          )}
          {goodsDetail!.ifPhoto === 'Y' && (
            <div className="prop-line mb-10 d-flex-r ai-center">
              <div className="label">手持身份证</div>
              <div className="value border-bottom flex-1 pic pb-5 d-flex-r">
                {handPhoto ? (
                  <div
                    className="upload-pic border-radius-4 overflow-hidden mr-5"
                    onClick={() => setHandPhoto(undefined)}
                  >
                    <img className="bw-100 bh-100" src={handPhoto} alt="" />
                    <div className="icon-close-block">
                      <img className="bw-100 bh-100" src="/pic/icon_close.png" alt="" />
                    </div>
                  </div>
                ) : (
                  <div className="upload-pic border border-radius-4 text-center">
                    <img className="icon-12" src="/pic/icon_plus.png" alt="" />
                    <input className="upload-input bw-100 bh-100" type="file" accept=".jpg,.png,.jpeg" />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="d-flex-r pb-10">
            <MCheckbox value={checkProtocol} onChange={(v) => setCheckProtocl(v)} />
            <div className="ml-10 font-size-12">
              <span onClick={() => setCheckProtocl(!checkProtocol)}>我已阅读并同意</span>
              <span className="text-color-p" onClick={() => setShowProtocol('book')}>
                《转卖或转借电话卡风险告知书》
              </span>
              <span className="text-color-p" onClick={() => setShowProtocol('inter')}>
                《客户服务协议》
              </span>
            </div>
          </div>
        </div>
        <div className="pl-30 pr-30 font-size-12 text-color-9">
          *请填写真实身份信息，未满16岁、一证五号、信息错误将无法发货。(所填信息皆做加密处理)
        </div>
        <div className="box-shadow bg-white border-radius-10 m-10">
          <div className="font-size-18 font-widget-600 text-center p-10">详情介绍</div>
          <div className="goods-detail pb-20" dangerouslySetInnerHTML={{ __html: goodsDetail!.goodsDetail }} />
        </div>
      </div>
      <div className="p-10">
        <div className="submit-btn" onClick={handleSubmit}>
          提交
        </div>
      </div>
      {showProtocol && <ProtocolDialog type={showProtocol} onClose={() => setShowProtocol(undefined)} />}
      {showSelectCardDialog && (
        <SelectPhoneDialog
          list={cardPool ?? []}
          value={selectCard}
          onChange={(v) => setSelectCard(v)}
          onClose={() => setShowSelectCardDialog(false)}
          onChangeNextPage={() => console.log('111')}
        />
      )}
    </Page>
  );
}
