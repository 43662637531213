import MobileSelect, { CascadeData } from 'mobile-select';
import React, { useRef, useState } from 'react';
interface Props {
  className?: string;
  title?: string;
  onChange: (v: any) => void;
  someProvince: string[];
  disable?: boolean;
}
export default function MSelectbox({ disable = false, onChange, someProvince, className, title }: Props) {
  const selectRef = useRef<MobileSelect | null>(null);
  const [select, setSelect] = useState(false);
  function handleRef(v: any) {
    if (selectRef.current) return;
    let data = someProvince;

    selectRef.current = new MobileSelect({
      trigger: '#m-select-picker',
      title: title,
      wheels: [{ data }],
      keyMap: { id: 'value', value: 'label', childs: 'children' },
      onChange: (cur: any) => {
        if (!select) {
          setSelect(true);
        }
        onChange((cur as CascadeData[]).map((i) => i));
      },
    });
  }
  return (
    <div
      id={!disable ? 'm-select-picker' : ''}
      className={`value ${!disable ? '' : 'bg-555 pl-10'} ${select ? '' : 'place-holder'}`}
      ref={(v) => handleRef(v)}
    >
      请选择
    </div>
  );
}
