import React from 'react';
interface Props {
  value: boolean;
  className?: string;
  onChange?: (v: boolean) => void;
}
export default function MCheckbox({ value = false, onChange, className }: Props) {
  function handleChange() {
    if (onChange) {
      onChange(!value);
    }
  }
  return (
    <div className={`m-checkbox ${className}`} onClick={handleChange}>
      <img className="m-size" src={value ? '/pic/icon_radio_checked.png' : '/pic/icon_radio_default.png'} alt="" />
    </div>
  );
}
