import React from 'react';
import Page from '../components/page';
import main from '../main';
import AppBar from '../components/appBar';
import Empty from '../components/empty';
import { useLocation } from 'react-router-dom';

export default function SubmitSuccessPage() {
  const { state } = useLocation();
  return (
    <Page
      style={main.pageBgStyle}
      className="detail-page d-flex-c"
      contentClassName="flex-1 h-1 overflow-y"
      appBar={<AppBar className="bg-transparent" title="提交结果" />}
    >
      <Empty tips={state.toString() || '提交成功'} pic="/pic/icon_submit.png" />
    </Page>
  );
}
