type PayRes = {
  /**
   * 200 =>成功
   * 300 =>等待
   * 500 =>失败
   */
  code: number;
  msg: string;
  url?: string;
};

export default async function showPayPage(data: any, payType: string): Promise<PayRes> {
  if (payType === PayType.wechatJsPay || payType === PayType.gatherPayJsWechat || payType === PayType.gmeJsPay) {
    return await wechatJsPay(data);
  }
  return { code: 500, msg: '不支持的支付方式,请联系客服' };
}

function wechatJsPay(data: any) {
  return new Promise<PayRes>((resolve, reject) => {
    // @ts-ignore
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      {
        appId: data.appId, //动态获取初始化请求中返回的pay_info参数中appId值
        timeStamp: data.timeStamp, //动态获取初始化请求中返回的pay_info参数中timeStamp值
        nonceStr: data.nonceStr, //动态获取初始化请求中返回的pay_info参数中nonceStr值
        package: data.packageValue, //动态获取初始化请求中返回的pay_info参数中package值
        signType: data.signType, //动态获取初始化请求中返回的pay_info参数中signType值
        paySign: data.paySign,
      },

      function (res: any) {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          resolve({ code: 200, msg: '支付成功' });
        } else {
          resolve({ code: 500, msg: '支付失败' });
        }
      }
    );
  });
}

/// 支付类型
export class PayType {
  /// HWC公众号支付
  static gatherPayJsWechat = 'gatherPayJsWechat';
  /// 官方公众号支付
  static wechatJsPay = 'wechatJsPay';
  // 瑞生意支付 第四方 微信支付
  static gmeJsPay = 'gmeJsPay';
  static typeStr(type?: string) {
    if (type === PayType.gatherPayJsWechat || type === PayType.wechatJsPay || type === PayType.gmeJsPay) {
      return '微信支付';
    }
    return '';
  }

  static typeIcon(type?: string) {
    if (type === PayType.gatherPayJsWechat || type === PayType.wechatJsPay || type === PayType.gmeJsPay) {
      return 'icon_pay_wx.png';
    }
    return 'icon_pay_balance.png';
  }
}
