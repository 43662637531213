import React, { useEffect } from 'react';

interface Props {
  onClose: () => void;
  message?: string;
  title?: string;
}

export default function MessageDialog({ title = '失败原因', message, onClose }: Props) {
  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
  }, []);

  function handleClose() {
    window.document.body.style.overflow = '';
    onClose();
  }
  return (
    <div className="message-dialog">
      <div className="message-mask" onClick={handleClose} />
      <div className="message-center" onClick={() => {}}>
        <div className="message-title-block">
          <div className="message-title">{title}</div>
          <div className="message-close" onClick={handleClose}>
            {/* <img alt="" className="close-icon" src={closeIcon} /> */}
            <img alt="" className="close-icon" src="/pic/icon_close_dialog.png" />
          </div>
        </div>
        <div className="message-content">{message}</div>
      </div>
    </div>
  );
}
