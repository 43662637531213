import React, { useEffect } from 'react';
import Page from '../components/page';
import main from '../main';
import { showToast } from '../utils/utils';
import { useNavigate } from 'react-router-dom';

export default function CustomerPage() {
  const navigate = useNavigate();
  useEffect(() => {
    console.log('main.serviceLink', main.serviceLink);
    if (!main.serviceLink) {
      showToast('该商家很懒，没有设置接待客服');
      navigate('/' + (main.shopId ? '?code=' + main.shopId : ''));
    } else if (main.serviceLink!.includes('work.weixin.qq')) {
      window.open(main.serviceLink!, '_blank');

      navigate('/' + (main.shopId ? '?code=' + main.shopId : ''));
    }
  }, []); // eslint-disable-line
  let isWx = main.serviceLink?.includes('work.weixin.qq');
  return (
    <Page navPage className="customer-page" contentClassName="bh-100">
      {isWx ? <div /> : <iframe title="客服" className="customer-iframe" src={main.serviceLink} />}
    </Page>
  );
}
