import React from 'react';
import { useNavigate } from 'react-router-dom';
interface Props {
  title: string;
  showBack?: boolean;
  onBack?: () => void;
  action?: React.ReactNode;
  className?: string;
}
export default function AppBar({ className, title, showBack = true, onBack, action }: Props) {
  const navigate = useNavigate();
  function handleBack() {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  }
  return (
    <div className={`app-bar ${className}`}>
      <div className="app-bar-title font-16">{title}</div>
      {showBack && <img className="app-bar-back" src="/pic/icon_back.png" alt="" onClick={handleBack} />}
      <div className="app-bar-action">{action}</div>
    </div>
  );
}
