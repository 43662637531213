import { useLocation } from 'react-router-dom';
import AppBar from '../components/appBar';
import Page from '../components/page';
import { parseLocationParams } from '../utils/utils';

export const protocolData = [
  {
    label: '电子渠道开户服务协议',
    value: 'p1',
    list: [
      '1.1',
      '1.2',
      '1.3',
      '1.4',
      '1.5',
      '1.6',
      '1.7',
      '1.8',
      '1.9',
      '1.10',
      '1.11',
      '1.12',
      '1.13',
      '1.14',
      '1.15',
      '1.16',
    ],
  },
  {
    label: '解约条款',
    value: 'p2',
    list: ['2.1', '2.2', '2.3'],
  },
  {
    label: '中国移动广东公司电子渠道商品销售服务协议',
    value: 'p3',
    list: ['3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8'],
  },
  {
    label: '中国移动通信客户服务协议',
    value: 'p4',
    list: [
      '4.1',
      '4.2',
      '4.3',
      '4.4',
      '4.5',
      '4.6',
      '4.7',
      '4.8',
      '4.9',
      '4.10',
      '4.11',
      '4.12',
      '4.13',
      '4.14',
      '4.15',
      '4.16',
      '4.17',
      '4.18',
      '4.19',
      '4.20',
    ],
  },
  {
    label: '隐私政策',
    value: 'p5',
    list: [
      '5.1',
      '5.2',
      '5.3',
      '5.4',
      '5.5',
      '5.6',
      '5.7',
      '5.8',
      '5.9',
      '5.10',
      '5.11',
      '5.12',
      '5.13',
      '5.14',
      '5.15',
    ],
  },
  {
    label: '电信网络诈骗犯罪及发送不良信息法律责任风险提示告知书',
    value: 'p6',
    list: ['6.1'],
  },
];

export default function ProtocolPage() {
  const location = useLocation();
  let { value } = parseLocationParams(location.search);

  let type = value || location.state;
  let item = protocolData.find((item) => item.value === type);
  if (!item) {
    item = protocolData[0];
  }
  return (
    <Page
      className="detail-page d-flex-c"
      contentClassName="flex-1 h-1 overflow-y"
      appBar={<AppBar className="bg-transparent" title={'查看协议'} />}
    >
      {item!.list!.map((i) => (
        <img key={i} className="bw-100 d-block" src={`/pic/protocol/${item?.value}/${i}.jpg`} alt="" />
      ))}
    </Page>
  );
}
