import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BottomNavBar from './components/bottomNavBar';
import CertificatesPage from './pages/certificatesPage';
import CustomerPage from './pages/customerPage';
import DetailPage from './pages/detailPage';
import HomePage from './pages/homePage';
import OrderListPage from './pages/orderListPage';
import ProtocolPage from './pages/protocolPage';
import SubmitSuccessPage from './pages/submitSuccessPage';
import TrainDetailPage from './pages/trainDetailPage';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BottomNavBar />}>
          <Route path="/" element={<HomePage />} />
          <Route path="customer" element={<CustomerPage />} />
          <Route path="order" element={<OrderListPage />} />
        </Route>
        <Route path="product" element={<DetailPage />} />
        <Route path="submit" element={<SubmitSuccessPage />} />
        <Route path="train" element={<TrainDetailPage />} />
        <Route path="protocol" element={<ProtocolPage />} />
        <Route path="cer" element={<CertificatesPage />} />
      </Routes>
    </BrowserRouter>
  );
}
