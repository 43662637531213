import React, { useState } from 'react';
import Page from '../components/page';
import Empty from '../components/empty';
import { Order } from '../types';
import { showToast } from '../utils/utils';
import { apiGetOrderList } from '../api';

export default function OrderListPage() {
  const [searchValue, setSearchValue] = useState('');
  const [orderList, setOrderList] = useState<Array<Order>>([]);
  const [errorTxt, setErrorTxt] = useState('请按手机号搜索订单');

  async function handleSearchOrder() {
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(searchValue)) {
      setErrorTxt('请输入正确的手机号');
      return;
    }
    showToast('正在查询...');
    try {
      let res = await apiGetOrderList(searchValue);
      let data = res.data;
      if (data.code === 200) {
        setOrderList(data.data);
      } else {
        setErrorTxt(data.msg ?? '暂无记录');
      }
    } catch (e: any) {
      setErrorTxt(e.message ?? '暂无记录');
    }
  }

  return (
    <Page navPage className="order-list-page d-flex-c" contentClassName="flex-1 h-1 d-flex-c">
      <div className="m-10 bg-white search-block box-shadow d-flex-r ai-center">
        <img className="icon-20" src="/pic/icon_search.png" alt="" />
        <input
          className="search-input flex-1 ml-10 mr-10"
          value={searchValue}
          placeholder="请输入手机号"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div className="search-btn text-center text-color-white" onClick={handleSearchOrder}>
          搜索
        </div>
      </div>
      <div className="flex-1 h-1 overflow-y">
        {!orderList.length && <Empty tips={errorTxt} showBackBtn={false} />}
        {orderList.map((i) => (
          <div key={i.orderSn} className="m-10 border-radius-10 bg-white p-10 box-shadow">
            <div className="prop-line">
              <div className="label">订单编号</div>
              <div className="flex-1">{i.orderSn}</div>
            </div>
            <div className="prop-line">
              <div className="label">订单名称</div>
              <div className="flex-1">{i.orderName}</div>
            </div>
            <div className="divider"></div>
            <div className="prop-line">
              <div className="label">开卡号码</div>
              <div className="flex-1">{i.activatePhone}</div>
            </div>
            <div className="prop-line">
              <div className="label">开卡说明</div>
              <div className="flex-1">{i.orderStatusDesc}</div>
            </div>
            <div className="divider"></div>
            <div className="prop-line">
              <div className="label">物流名称</div>
              <div className="flex-1">{i.expressName}</div>
            </div>
            <div className="prop-line">
              <div className="label">物流单号</div>
              <div className="flex-1">{i.expressNum}</div>
            </div>
            <div className="prop-line">
              <div className="label">下单时间</div>
              <div className="flex-1">{i.expressTime}</div>
            </div>
          </div>
        ))}
      </div>
    </Page>
  );
}
