import React from 'react';
import { useNavigate } from 'react-router-dom';
import main from '../../main';
interface Props {
  pic?: string;
  tips?: string;
  className?: string;
  backBtnTxt?: string;
  backBtnUrl?: string;
  showBackBtn?: boolean;
}
export default function Empty({ className, backBtnTxt, backBtnUrl, showBackBtn, pic, tips }: Props) {
  const navigate = useNavigate();
  return (
    <div className={'empty d-flex-c ai-center bw-100 mt-40 ' + className}>
      <img className="pic" src={pic || '/pic/icon_empty.png'} alt="" />
      <div className="tips mt-30 text-center">{tips || '暂无数据'}</div>
      {main.shopId && showBackBtn && (
        <div className="back-btn mt-30" onClick={() => navigate(backBtnUrl || `/?code=${main.shopId}`)}>
          {backBtnTxt || '返回首页'}
        </div>
      )}
    </div>
  );
}
