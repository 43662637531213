import React, { useEffect } from 'react';

interface Props {
  /** 协议类型 book=>转卖或转借电话卡风险告知书 inter=>客户服务协议 noticeYD=>移动关于客户个人信息收集，使用及保护的公告 internet=>入网协议 */
  type: 'book' | 'inter' | 'noticeYD' | 'internet' | 'userInfo' | 'noticeDX' | 'phone' | string;
  onClose: () => void;
  supName?: string;
}

export default function ProtocolDialog({ type = 'book', onClose, supName }: Props) {
  let noticeDXProtocol = {
    title: '中国电信用户入网协议',
    content: [
      '甲方：',
      `乙方：${supName?.indexOf('湖南电信') ? '中国电信XX分公司' : '中国电信湖南分公司'} `,
      '重要提示：',
      '1.甲方应当为具有完全民事行为能力的自然人。如甲方为无民事行为能力人或限制民事行为能力人，甲方签署本协议应当经过其法定代理人同意并由法定代理人同时签署本协议。',
      '2.在签署本协议前，请甲方或其代理人仔细阅读本协议各条款，如有疑问请及时咨询。',
      '3.乙方尊重并保护用户的个人信息，制定了《中国电信个人信息保护政策》，已向甲方告知有关乙方提供本协议项下服务适用的个人信息处理规则。',
      '4.如甲方为不满十四周岁的未成年人的，甲方父母或其他监护人须同时签署《中国电信儿童个人信息处理知情同意书（适用于不满十四周岁的未成年用户）》（“知情同意书”）。乙方尊重并保护儿童个人信息，制定了《中国电信儿童个人信息处理规则》，作为知情同意书的附件，向甲方告知有关乙方提供本协议项下服务适用的儿童个人信息处理规则。',
      '5.甲方或其代理人/监护人签署本协议即视为完全理解并同意接受本协议的全部条款。',
      '6.甲方所需的业务内容、办理方式以及资费方案请详见乙方的相关业务说明。',
      '根据《中华人民共和国民法典》《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《中华人民共和国网络安全法》《中华人民共和国反电信网络诈骗法》《中华人民共和国电信条例》及其他有关法律、法规的规定，甲乙双方在自愿、平等、公平、诚实信用的基础上，就电信服务的相关事宜，达成如下协议：',
      '一、用户真实身份信息登记要求',
      '1.根据《中华人民共和国反电信网络诈骗法》《电话用户真实身份信息登记规定》（工业和信息化部令第25号）及有关登记实施规范要求，甲方办理入网手续时须向乙方出示二代居民身份证、外国人永久居留身份证（仅限外国公民使用）、提供真实身份信息，并通过乙方在线视频实人认证等认证方式完成身份信息核验（具体认证方式以乙方网络渠道页面提示为准）。如甲方申办乙方移动电话卡（含无线上网卡），但未完成在线实人认证程序的，乙方将委托第三方递送电话卡给甲方，并授权第三方现场查验甲方身份信息、收集相关资料，甲方须予以配合。',
      '2.甲方应当保证登记的信息及提供的身份信息登记资料真实、有效、准确、完整，并有义务配合乙方或乙方授权的第三方进行查验。甲方可以通过乙方相应渠道查询已登记的身份信息。如不符合用户真实身份信息登记相关法律法规和规定、或登记信息发生变化的，甲方应当及时到乙方营业网点更正或变更。因甲方登记信息不符合规定或未及时办理更正或变更手续等造成的后果，由甲方自行承担。',
      '3.甲方申请入网时，在中国电信全国范围内登记在同一个个人用户名下的移动电话卡达到5张的，乙方根据国家相关规定不再为其开办新的移动电话卡。',
      '4.乙方有权按照《中华人民共和国反电信网络诈骗法》，对监测识别的涉诈异常电话卡用户重新进行真实身份信息核验。对未按规定核验或核验未通过的，乙方有权限制、暂停有关电话卡功能。',
      '二、甲方的权利和义务',
      '1.甲方有权监督乙方的电信服务质量，及时提出改善意见和建议；对电信服务持有异议的，可以向乙方进行咨询或投诉。',
      '2.甲方有权自主选择乙方依法提供的各类电信业务，有权自主选择乙方公示的资费方案，有权自主选择乙方提供的各类业务办理、咨询、查询和投诉渠道。对于部分乙方业务， 甲方有权自业务办理成功后7日内按照相应的业务规则申请办理退订且无需因退订支付违约金。具体适用业务范围及退订规则（包括但不限于甲方应当交纳已使用的电信费用）以甲方办理业务时签署的业务登记单或乙方公示信息为准。',
      '3.甲方有权自主选择取得入网许可的终端、设备，并负责安装、调测和维护（包括建筑内自建管线的维护）。甲方自主选择的终端、设备应当具备符合乙方网络的相应功能，否则可能无法支持所选择的电信服务，甲方将自行承担损失。',
      '4.甲方对乙方提供给甲方使用但所有权归乙方的线路、设备或终端有保管责任，不得转借、出租、出售或赠与他人，也不得用于非乙方提供的业务中。由于甲方原因造成该等线路、设备或终端损坏、丢失的，甲方需承担修复费用或按价赔偿。',
      '5.甲方应当按照约定的交费时间和方式及时、足额交纳电信费用。电信费用计费周期一般为自然月，即每月1日0时至当月最后一日24时，双方另有约定的除外。逾期交纳电信费用（欠费）的，甲方须每日按所欠费用3‰的标准支付违约金，并承担相应违约责任。由于通信设备产生费用清单及相关处理会有时延（如国际漫游业务等），可能会发生上一计费周期的部分电信费用计入下一计费周期收取的情况。',
      '6.甲方可以自愿订购或退订第三方服务提供商通过乙方网络提供的增值电信服务，并同意乙方根据第三方服务提供商的委托代为收取相关费用。如甲方对代收费用有异议，可以向第三方服务提供商或乙方提出，由第三方服务提供商自行解决或由乙方协调第三方服务提供商解决。',
      '7.甲方对交纳的电信费用及代收费用有异议的，应当在乙方计费原始数据保存期限内向乙方提出。',
      '8.甲方有权自主选择或终止以代扣、银行托收等方式支付电信费用，并自主到银行等机构或具备受理条件的营业网点等渠道办理相应手续。',
      '9.甲方通信自由和通信秘密受法律保护。公安机关、国家安全机关、人民检察院及依据法律法规有权进行调查的其他部门根据相关规定提出调查要求的，乙方将依法配合。',
      '10.甲方不得非法买卖、出租、出借名下的电话卡、物联网卡、电信线路、短信端口等。甲方应当确保电信服务的实际使用人信息与乙方登记信息一致，如需转让给他人使用或实际使用人信息发生变化，甲方须按本协议关于用户真实身份信息登记的约定，办理过户手续或变更实际使用人信息，否则乙方有权采取关停相关服务或功能、解除协议以及有权机构要求的其他措施。由此产生的后果和法律责任，由甲方全部承担。',
      '11.甲方不得利用乙方通信资源、电信服务等制作、复制、发布、传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：',
      '（1）反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建迷信的；',
      '（2）危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
      '（3）宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；',
      '（4）散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会秩序，破坏社会稳定的；',
      '（5）散布涉及实施诈骗，制作或销售违禁物品、管制物品，淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；',
      '（6）侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；',
      '（7）发送违法信息、拨打诈骗电话，未经接收方同意或请求、或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息、拨打骚扰话音或商业性话音的；',
      '（8）其他利用乙方通信资源、电信服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。',
      '12.甲方不得实施或协助他人实施下列危害乙方网络安全和信息安全的行为，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：',
      '（1）侵入或非法控制乙方网络或电信设备，盗接乙方电信线路、非法复制乙方电信码号；',
      '（2）非法获取、删除、修改乙方网络或电信设备中存储、处理、传输的信息和应用程序；',
      '（3）利用乙方网络从事窃取或破坏他人信息、损害他人合法权益的活动，制作、传播、使用网络改号软件以及对他人进行骚扰的恶意软件；',
      '（4）提供从事入侵乙方网络、干扰网络正常功能、传输扩散违法信息、窃取网络数据等危害网络安全活动的工具及制作方法；',
      '（5）设立用于实施诈骗，传授犯罪方法，制作或销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的；',
      '（6）故意制作、复制、传播计算机病毒或以其他方式攻击网络等电信设施；',
      '（7）使用未取得入网许可或可能影响网络安全、网络质量的终端设备或软件的；',
      '（8）危害乙方网络安全和信息安全的其他行为。',
      '13.甲方同意乙方基于提供电信服务、保障通信服务安全、与甲方沟通联系目的处理并与乙方关联公司共享甲方信息，包括身份信息、服务数据及日志信息等。身份信息包括但不限于通过识别仪、高拍仪、纸质表单、互联网等方式收集的姓名、出生日期、身份证件号码等身份证件登记信息、拍摄的影像、住址、电话号码、电子邮箱等；服务数据及日志信息包括但不限于甲方在使用乙方服务过程中上传和产生的位置信息、终端信息、通话记录、使用记录、订购信息、账单、网络信息等。乙方处理甲方个人信息的规则，见《中国电信个人信息保护政策》。如甲方不同意提供或不同意乙方处理如上的某一或某些信息，甲方将可能无法成为乙方的用户或无法享受乙方提供的某些服务，或无法达到相关服务拟达到的效果。本协议解除或终止后，乙方不再收集甲方新的信息，并将按照相关法律法规规定对本协议存续期间收集的甲方信息进行处理。其中乙方关联公司是指乙方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司或机构管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。',
      '14.未经甲方授权同意，乙方不向第三方提供甲方信息。如甲方授权与乙方有合作关系的银行等金融机构、依法设立的征信机构、数据服务机构、信息查询服务机构、信息验证服务机构、互联网企业等第三方向乙方收集、核验甲方信息，甲方同意乙方有权向该等第三方提供相关的甲方信息和核验信息。',
      '15.为保护公共利益、优化电信服务质量、保障甲方电信服务知情权、及时警示通讯信息诈骗、创新电信服务体验，甲方同意乙方使用甲方信息通过营业网点、网站、短信、互联网媒体、电子邮件、语音外呼等方式，向甲方告知社会公益、电信服务、业务使用、诈骗风险警示等信息。',
      '16.甲方有权自主选择携号转网服务，即在保持移动电话号码不变的情况下变更签约的电信运营企业。甲方办理携号转网业务应当符合工业和信息化部携号转网有关文件的规定条件和程序。',
      '17.办理携号转网业务过程中，甲方同意乙方将必要的甲方身份信息提供给工业和信息化部集中业务管理系统（CSMS系统），用于比对携入方和携出方登记的身份信息是否一致。',
      '三、乙方的权利和义务',
      '1.乙方在其通信网络与设施覆盖范围内，向甲方提供电信服务；其中，乙方在签订有自动漫游协议的国家和地区的电信运营商的网络覆盖范围内为甲方提供国际移动通信漫游服务及港澳台地区移动通信漫游服务。乙方提供的电信服务应符合国家规定的电信服务质量标准。',
      '2.乙方免费向甲方提供中华人民共和国境内（为本协议目的，仅限大陆地区，不含港澳台）火警、匪警、医疗急救、交通事故报警等紧急呼叫服务。',
      '3.乙方根据生产经营成本、电信市场供求状况等因素，合理确定并公示电信业务资费方案。',
      '4.乙方可以通过营业网点、网站、电话、广播、短信、彩信、电子邮件、电视、公开张贴、信函、报纸、互联网媒体等一种或数种方式，公布、通知或告知业务种类和服务项目、范围、时限、资费方案以及其他电信服务内容。',
      '5.乙方可以通过电话热线、营业网点、网上营业厅、掌上营业厅或短信等多种渠道为甲方提供业务办理、咨询、查询、障碍申告、投诉受理等服务。',
      '6.乙方负责网络接入保障、提供相关设备的安装、调测和维护服务，具体服务内容及费用等详见相关业务说明。',
      '7.甲方向乙方申告除网络覆盖和终端设备故障外的电信服务障碍，乙方应当自接到申告之日起，城镇48小时、农村72小时内修复或调通。',
      '8.乙方因检修线路、设备搬迁、工程施工、网络建设等原因可能影响正常电信服务的，应当提前72小时向社会公告，通告方式包括但不限于网站、广播、短信、电视、公开张贴、报纸、互联网媒体等。',
      '9.乙方向甲方提供免费的电子账单服务，并有义务对账单进行解释。乙方对甲方计费原始数据保留期限为5个月（系统产生用户话单当月起后5个月，不含当月），法律另有规定除外。如数据保留期限内甲方对电信费用提出异议，乙方应当保存相应计费原始数据至异议解决。',
      '10.乙方可以根据对甲方实际情况的综合评估，与甲方约定一定时间内的电信消费透支额度（“信用额度”）并可以进行周期性调整。',
      '11.乙方有权根据具体业务类型按预付方式或后付方式向甲方收取电信费用：',
      '（1）预付方式下，甲方需预存费用。当账户余额加上信用额度（如有）不足以支付甲方拟使用的电信业务费用时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务（“欠费停机”）。',
      '（2）后付方式下，甲方累计未交的费用到达信用额度、或到达交费期时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务。其中，甲方当月累计出账费用的交费期为次月整月，另有约定的除外。',
      '12.甲方自欠费停机之日起60日内结清费用并申请复机的，乙方应当在收到甲方费用时起24小时内为甲方恢复暂停的电信服务（“复机”）。',
      '13.如甲方自欠费停机之日起满60日仍未结清欠费和违约金，乙方有权自欠费停机第61日起终止提供电信服务，收回相应号码（“销户”）、解除本协议，以及通过信函、电话、诉讼或委托第三方等形式追缴欠费和违约金，并可以依法向征信机构提供甲方失信信息。',
      '14.对经设区的市级以上公安机关认定的非法买卖、出租、出借电话卡、物联网卡、电信线路、短信端口以及假冒他人身份或者虚构代理关系开立上述卡或业务的单位、个人，以及因从事电信网络诈骗活动或者关联犯罪受过刑事处罚的人员，乙方有权按照国家有关规定采取限制其有关卡、账户、账号等功能和停止非柜面业务、暂停新业务、限制入网等措施。',
      '15.乙方严格按照《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《中华人民共和国反电信网络诈骗法》《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）等法律法规的相关要求，在业务活动（包括但不限于业务受理系统登记、纸质返档，通过网络接收、读取并记录、身份验证、客户服务、安全防范、诈骗监测、邀请甲方参与有关乙方产品和服务的调查）中处理甲方信息。为提供更为精准优质的服务，乙方可以依法对包含甲方在内的整体用户数据进行分析并加以利用。乙方承诺对甲方信息依法负有保密义务，不得泄露、篡改或毁损，不得非法出售或非法向他人提供，并采取符合业界标准、合理可行的安全防护措施保护甲方的个人信息，防止甲方的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。',
      '16.为遵守相关法律法规或者行政机关、监管机构等有权部门依法提出的要求以及基于诉讼/仲裁需要，乙方有权披露甲方的信息。',
      '四、特殊情况的责任承担',
      '1.甲方入网后，应当妥善保管原始票据（如押金票据等），发生遗失的，由甲方自行承担责任。',
      '2.甲方密码是甲方办理业务的重要凭证。甲方入网后应当立即设置甲方密码，并妥善保管。为方便办理业务，乙方也可以根据甲方申请以短信等方式提供随机密码，该密码可以作为办理业务的临时凭证。',
      '3.凡使用甲方密码或随机密码定制、变更或取消业务的行为，均被视为甲方或甲方授权的行为。因甲方对密码保管不善造成的损失由甲方自行承担，但乙方有义务协助甲方或公安机关调查相关情况。',
      '4.甲方应当合理管理电信费用支出，出现异常的高额电信费用时（指超过甲方此前3个月平均电信费用5倍以上的费用），乙方一经发现，应当尽可能迅速告知甲方，如告知后未得到甲方确认的，乙方有权暂停本协议约定的部分或全部服务。',
      '5.在甲方欠费停机的情况下，乙方有权拒绝为甲方办理除交费、电信费用查询外的其他电信业务。',
      '6.一方违约给对方造成直接损失的，应当依法承担赔偿责任。',
      '7.因不可抗力而使本协议部分或全部不能履行的，部分或全部免除责任，但法律另有规定的除外。不可抗力指不能预见、不能避免并不能克服的客观情况。',
      '8.甲方使用本协议项下电信服务过程中涉及的第三方服务（如第三方支付、银行转账、与第三方应用绑定等），由第三方承担责任。对于甲方提出的异议，乙方可以协调第三方解决。',
      '9.乙方可能重新启用销户的移动电话号码。号码被销户前原使用该号码的用户（“原机主”）如将该号码用作第三方联系电话或者绑定第三方业务，且原机主未在销户前予以变更或解除绑定，该号码可能会收到第三方发给原机主的通知，或者因第三方的限制无法在解除绑定前完成新用户认证绑定。甲方需自行办理解除绑定等手续，乙方在合理限度内提供配合。',
      '五、协议的生效、变更、终止和解除',
      '1.除本协议另有约定外，本协议自甲方以点击确认或其他方式表示同意本协议时成立，自甲方按本协议第一条第1款完成身份信息线上核验或现场查验时生效。如甲方办理号码携入的，本协议自甲方以点击确认或其他方式表示同意本协议时成立，于甲方按本协议第一条第1款完成身份信息线上核验或现场查验、乙方收到携号转网成功生效结果告知之时起生效。',
      '2.甲方如终止使用电信服务，应当结清电信费用及违约金（如有），并至乙方营业网点申请销户或号码携出，销户或号码携出后本协议终止。',
      '3.未经双方同意并办理有关手续，甲、乙方不得将依据本协议办理的业务全部或部分转让给第三方。',
      '4.甲方申请销户或号码携出时账户仍有余额的，乙方根据实际情况将账户余额退还甲方，双方另有约定的除外；甲方通过参与赠送、优惠、兑换等营销活动获取的余额，不予退还，如按营销活动的业务规则另有约定的，按其业务规则处理。',
      '5.因乙方电信业务经营许可证的业务范围、业务种类发生改变，或因乙方对移动通信网络进行整体换代升级、技术进步、国家政策变动等原因，乙方无法向甲方继续提供原有业务或需对原有业务的服务方式、服务功能、操作方法、业务号码等进行调整时，乙方有权变更或终止本协议，但应当提前通知或公告，提出解决方案，做好客户服务。',
      '6.有下列情形之一的，乙方有权单方终止或解除本协议：',
      '（1）甲方未按照用户真实身份信息登记要求，提供的登记资料不真实、不准确、不完整、无效，或经乙方通知后未在合理期限内配合提供的；',
      '（2）甲方收到电话卡后20天内未激活使用的；',
      '（3）甲方存在本协议第二条第11款、第12款所列任一行为，或由此引起用户投诉或举报的；',
      '（4）甲方未办理相关手续，自行改变电信服务使用性质、用途，或出租、二次转售、倒卖电话卡，或自行转让协议的；',
      '（5）甲方利用乙方服务从事相应活动，依法应当取得而未取得或丧失相应许可或备案；',
      '（6）甲方自欠费停机之日起60日内仍未结清所有欠费、违约金、赔偿金等费用的；',
      '（7）有权机关要求乙方停止对甲方提供电信服务的；',
      '（8）甲方原因造成本协议无法履行的；',
      '（9）法律、法规、规章及规范性文件规定的其他情形。',
      '7.本协议终止或解除后，乙方继续保留向甲方追缴所欠费用的权利，并有权收回甲方原使用的业务号码（销户的号码）。乙方对销户的业务号码冻结90日后将重新分配给其他用户使用。',
      '8.甲方改号、销户或过户时应当主动注销或解绑在该号码上的注册或绑定的第三方应用（如微信、支付宝）、银行卡（如银行验证号码）等信息、以及第三方软件，自行解除银行托收、代扣等第三方协议，自行卸载甲方加载的第三方软件，避免造成甲方信息泄露；否则由此造成甲方或其他人损失由甲方承担。',
      '9.为使甲方便捷使用乙方或其关联公司提供的产品或服务、保障信息安全，如甲方通过其在乙方办理的移动电话号码使用（包括但不限于登录、真实身份验证等）乙方或乙方关联公司运营的各类互联网应用（统称“中国电信互联网应用”）的，中国电信互联网应用将默认该移动电话号码作为甲方的中国电信互联网应用账号（统称“天翼账号”）。当甲方对该移动电话号码进行改号、销户或过户时，中国电信互联网应用将自动注销甲方的上述天翼账号，并同步删除该天翼账号中的全部信息。甲方应当在改号、销户或过户前另行备份上述天翼账号中的信息。如甲方未备份造成信息丢失的，由甲方自行承担，乙方或乙方关联公司不承担责任且无义务为甲方进行信息恢复。中国电信互联网应用包括但不限于189邮箱、天翼云盘。',
      '六、其他约定',
      '1.本协议适用中华人民共和国法律（仅为本协议之目的，不含香港特别行政区、澳门特别行政区和台湾地区法律）。所有因本协议引起的或与本协议有关的争议，可以通过协商、请求消费者协会或依法成立的其他调解组织调解（限于甲方为消费者）、向有关行政部门投诉，或者向乙方所在地的人民法院起诉等方式解决。',
      '2.如果本协议的任何条款在任何时候变成不合法、无效或不可强制执行但不从根本上影响本协议的效力时，本协议的其他条款不受影响。',
      '3.甲方办理各类业务所签署的业务登记单为本协议的一部分，业务登记单与本协议条款冲突的部分以业务登记单为准。乙方以公告等书面形式公开做出的服务承诺为本协议的补充，该等服务承诺与本协议冲突的部分以服务承诺为准。',
      '4.有关乙方提供本协议项下服务适用的个人信息处理规则，见《中国电信个人信息保护政策》。《中国电信个人信息保护政策》与本协议条款冲突的部分以《中国电信个人信息保护政策》为准。',
      '5.如甲方为不满十四周岁的未成年人的，甲方父母或其他监护人签订本协议时须同时签署《中国电信儿童个人信息处理知情同意书（适用于不满十四周岁的未成年用户）》（“知情同意书”）。甲方同意乙方按照知情同意书及其附件《中国电信儿童个人信息处理规则》处理甲方的个人信息。知情同意书及其附件《中国电信儿童个人信息处理规则》为本协议的一部分，知情同意书及其附件《中国电信儿童个人信息处理规则》与本协议条款冲突的部分以知情同意书为准。',
      '6.本协议一式两份，双方各保留一份，具有同等法律效力。甲乙双方以电子方式签署本协议的，甲方可以通过乙方提供的途径或指引查询、下载本协议。',
      '甲方：（签名或单位盖章）',
      '地址：',
      '联系方式：',
      '签署日期： 年 月 日',
      '甲方法定代理人或代理人：（签名或单位盖章）',
      '地址：',
      '联系方式：',
      '签署日期： 年 月 日',
      '乙方：（名称并盖章）',
      '地址：（乙方单位注册地址）',
      '签署日期： 年 月 日',
    ],
  };
  let p =
    type === 'book'
      ? userProtocol
      : type === 'inter'
      ? interProtocol
      : type === 'phone'
      ? phoneProtocol
      : type === 'noticeYD'
      ? noticeYDProtocol
      : type === 'noticeDX'
      ? noticeDXProtocol
      : type === 'userInfo'
      ? userInfoProtocol
      : type === 'internet'
      ? internetProtocol
      : { title: '', content: [] };
  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
  }, []);

  function handleClose() {
    window.document.body.style.overflow = '';
    onClose();
  }
  return (
    <div className="protocol-dialog">
      <div className="protocol-mask" onClick={handleClose} />
      <div className="protocol-center" onClick={() => {}}>
        <div className="protocol-title-block">
          <div className="protocol-title">{p.title}</div>
          <div className="protocol-close" onClick={handleClose}>
            <img alt="" className="close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="protocol-content">
          {p.content.map((v, i) => (
            <div key={i} className="protocol-content-line">
              {v}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

let closeIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACSUlEQVR4nO2avXKbQBSF9Th6CBUpXbpwYcfyT+L8PIr2nLPvlDKly8SWnThOYj+AyxRKEcggBliBgAXmfjOUcPd8u8Cyy2xmGIZhGIZhGIZh1Md7P3fOLfqu65xbeO/nfdfdwns/J/kM4KVPCc65BYAXks/RJKxWq1ckP5HcJMc3ABdd13XOHZP8kqn7meRB13W3KAj/XwLJ867qFoSPIyEzBPMN2XR1O1TVJPkHwGHbNSsBcJH0eFGD7gCctVWrouc3AJ4kfWyrVi1InpO8L5MgablvjarwJH+T/NBGlsZUSZC03kfC4MOneO/PSN6VDNE1gNO616wKL+mXpPddZGkMgFIJJG/rSAj0/PDCp0haSlqXSZD0OnSNQPifJN/1kaUxkpYACiVIunHOlUoYffgUAKckb0ueCTckT/LnBO75R0lXMbI0pkoCya9ZCYGefwQwrvApu8wYd5jhHcXOsRchCZMOn0LyJBn2RUGLjgdJb2K3u1UkHe8o4YHkZez2dkIioexhtyH5fbLhZ7Pg037aAnYIP10JNcJPT0LoPZ8cpfOE2O3fi10mOQCOJikhMOy3XnUkL5NhP43bIRD+R9EkZzISQuFJvi07d/QSQl91VeFTRish9D1f55N2dBJCKzlNFjNGIyG0gMk9lrEGL6HL8CmDlRDatGhz6XpwEmIsYw1qxkjygP+2pPMNeWKH21UVI+G6993hvARJnYbP1M1L6D98SvqLTFfDvozM7RDvF5kU7/08Rg8AOIwe3jAMwzAMwzAMY6z8BS3ngNlcif7iAAAAAElFTkSuQmCC';

let userProtocol = {
  title: '关于客户个人信息收集、使用规则的公告',
  content: [
    '尊敬的客户：',
    '根据《中华人民共和国反恐怖主义法》《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》(工业和信息化部令第24号)和《电话用户真实身份信息登记规定》(工业和信息化部令第25号)等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点(含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等)办理固定电话、移动电话(含无线上网卡)入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息，根据业务受理情况，必要时会致电客户本人，客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。',
    '如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。',
    '为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息:',
    '(a)事先获得客户的明确授权;',
    '(b)根据有关的法律法规要求；',
    '(c)按照相关司法机关和/或政府主管部门的要求；',
    '(d)为维护社会公众的利益所必需且适当；',
    '(e)为维护我公司或客户的合法权益所必需且适当。',
  ],
};

let interProtocol = {
  title: '客户服务协议',
  content: [
    '1.您应当为具有完全民事行为能力的自然人、个体工商户、农村承包经营户、 法人、非法人组织、获得法人或非法人组织授权的依法登记的经营单位。 如您为无民事行为能力人或限制民事行为能力人， 您签署本协议应当经过其法定代理人同意并由法定代理人同时签署本协议。',
    '2.在签署本协议前，请您或其代理人仔细阅读本协议各条款，如有疑问请及时咨询。',
    '3.您或其代理人签署本协议即视为完全理解并同意接受本协议的全部条款。',
    '4.您所需的业务内容、办理方式以及资费方案请详见相关业务说明。 根据《中华人民共和国合同法》《中华人民共和国网络安全法》 《中华人民共和国电信条例》及其他有关法律、 法规的规定，您同意就我方提供的通信服务的相关事宜，达成如下协议：',
    '一、用户真实身份信息登记要求',
    '1.根据《电话用户真实身份信息登记规定》（工业和信息化部令第25号） 及有关登记实施规范要求，您办理入网手续时须向我方出示有效证件、 提供真实身份信息；您委托他人办理入网手续的，代理人须出示您和代理人的有效证件， 提供您和代理人的真实身份信息；并且配合我方进行身份信息查验、登记，留存办理手续的记录。',
    '2.您为个人用户的，线上办理入网时使用二代居民身份证。',
    '3.您为单位用户的，办理入网时应出示统一社会信用代码证、或营业执照、 或事业单位法人证书、社会团体法人登记证书、或法律、 行政法规和国家规定的其他有效证件或者证明文件。 单位办理登记的，除出示以上相应证件外，还应当出示经办人的有效证件和单位的授权书。',
    '4.单位用户办理电话入网时， 必须向我方提供与每一张电话卡一一对应的实际使用人的有效证件， 由我方进行查验并登记实际使用人的身份信息。',
    '5.您应当保证登记的信息及提供的身份信息登记资料真实、有效、准确、完整， 并有义务配合我方进行查验。您可以通过我方相应渠道查询已登记的身份信息。 如不符合用户真实身份信息登记相关法律法规和规定、或登记信息发生变化的， 您应当及时到我方营业网点更正或变更。 因您登记信息不符合规定或未及时办理更正或变更手续等造成的后果，由您自行承担。',
    '6.您申请入网时，在中国电信全国范围内登记在同一个个人用户名下的电话卡达到 5张的，我方根据国家相关规定不再为其开办新的电话卡。',
    '7.我方要求您提供担保的，您应当按照我方要求办理相应担保手续。',
    '二、您的权利和义务',
    '1.您有权监督我方的通信服务质量，及时提出改善意见和建议； 对我方提供的服务持有异议的，可以向我方进行咨询或投诉。',
    '2.您有权自主选择我方依法提供的各类通信业务，有权自主选择我方公示的资费方案， 有权自主选择我方提供的各类业务办理、咨询、查询和投诉渠道。',
    '3.您有权自主选择取得入网许可的终端、设备，并负责安装、 调测和维护（包括建筑内自建管线的维护）。 您自主选择的终端、设备应当具备符合我方网络的相应功能， 否则可能无法支持所选择的通信服务，您将自行承担损失。',
    '4.您对我方提供给您使用但所有权归我方的线路、设备或终端有保管责任， 不得转借、出租、出售或赠与他人，也不得用于非我方提供的业务中。 由于您原因造成该等线路、设备或终端损坏、丢失的，您需承担修复费用或按价赔偿。',
    '5.您应当按照约定的交费时间和方式及时、足额交纳通信费用。 通信业务一般按自然月为一个计费与结算周期，双方另有约定的除外。 逾期交纳通信费用（欠费）的，您须每日按所欠费用3‰的标准支付违约金， 并承担相应违约责任。由于通信设备产生费用清单及相关处理会有时延（如国际漫游业务等）， 可能会发生上一计费周期的部分通信费用计入下一计费周期收取的情况。',
    '6.您可以自愿订购或退订第三方服务 提供商通过我方网络提供的增值通信服务， 并同意我方根据第三方服务提供商的委托代为收取相关费用。 如您对代收费用有异议，可以向第三方或我方提出，由第三方自行解决或由我方协调第三方解决。',
    '7.您对交纳的通信费用及代收费用有异议的，应当在我方计费原始数据保存期限内向我方提出。',
    '8.您有权自主选择或终止以代扣、 银行托收等方式支付通信费用， 并自主到银行等机构或具备受理条件的营业网点等渠道办理相应手续。',
    '9.您通信自由和通信秘密受法律保护。公安机关、国家安全机关、 人民检察院及依据法律法规有权进行调查的其他部门根据相关规定提出调查要求的，我方将依法配合。',
    '10.您不得二次转售、倒卖名下持有的电话卡。 如需转让给他人使用或实际使用人信息发生变化， 您须按本协议关于用户真实身份信息登记的约定， 办理过户手续或变更实际使用人信息， 否则我方有权采取关停号码、解除协议等措施。 由此产生的后果，我方不承担责任。',
    '11.您不得利用我方通信资源、通信服务等制作、复制、发布、 传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动， 否则我方有权暂停或限制向您提供通信服务， 直至终止通信服务。由此产生的后果，我方不承担责任：',
    '（1）反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建迷信的；',
    '（2）危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
    '（3）宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；',
    '（4）散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会秩序，破坏社会稳定的；',
    '（5）散布涉及实施诈骗，制作或销售违禁物品、管制物品，淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；',
    '（6）侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；',
    '（7）发送违法信息、拨打诈骗电话，未经接收方同意或请求、 或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息、拨打骚扰话音或商业性话音的；',
    '（8）其他利用 我方通信资源与服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。',
    '12.您不得实施或协助他人实施下列危害我方网络安全和信息安全的行为， 否则我方有权暂停或限制向您提供通信服务，直至终止通信服务。 由此产生的后果，我方不承担责任：',
    '（1）侵入或非法控制我方网络或电信设备，盗接我方电信线路、非法复制我方电信码号；',
    '（2）非法获取、删除、修改我方网络或电信设备中存储、处理、传输的信息和应用程序；',
    '（3）利用我方网络从事窃取或破坏他人信息、损害他人合法权益的活动， 制作、传播、使用网络改号软件以及对他人进行骚扰的恶意软件；',
    '（4）提供从事入侵我方网络、干扰网络正常功能、传输扩散违法信息、窃取网络数据等危害网络安全活动的工具及制作方法；',
    '（5）设立用于实施诈骗，传授犯罪方法，制作或销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的；',
    '（6）故意制作、复制、传播计算机病毒或以其他方式攻击网络等电信设施；',
    '（7）使用未取得入网许可或可能影响网络安全、网络质量的终端设备或软件的；',
    '（8）危害我方网络安全和信息安全的其他行为。',
    '13.您同意我方基于提供通信服务、与您沟通联系、改善服务质量等目的， 收集、储存并使用您个人信息，包括身份信息、服务数据及日志信息等。 身份信息包括但不限于通过识别仪、高拍仪、纸质表单、互联网等方式收集的姓名、 出生日期、身份证件号码、拍照人像、住址、电话号码、电子邮箱等； 服务数据及日志信息包括但不限于您在使用我方服务过程中上传和产生的位置信息、 终端信息、通话记录、使用记录、订购信息、账单等。如您不同意提供或不同意我方收集、 储存并使用如上的某一或某些信息，您将可能无法成为我方的用户或无法享受我方提供的某些服务， 或无法达到相关服务拟达到的效果。本协议解除或终止后，我方不再收集新的您个人信息， 并将按照相关法律法规规定对本协议存续期间收集的您个人信息进行处理。',
    '14.未经您授权同意，我方不向除我方关联公司外的第三方提供您个人信息。 如您授权与我方有合作关系的银行、依法设立的其他征信机构、数据服务机构、 信息查询服务机构及信息验证服务机构等第三方向我方收集、核验您个人信息， 您同意我方有权向该等第三方提供相关的个人信息和核验信息。 其中我方关联公司是指我方现在或将来控制、受控制或与其处于共同控制下的任何公司、 机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力， 无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。',
    '15.为保护公共利益、优化通信服务质量、保障您通信服务知情权、及时警示通讯信息诈骗、 创新通信服务体验，您同意我方使用您个人信息通过营业网点、 网站、短信、互联网媒体、电子邮件、语音外呼等方式，向您告知社会公益、 通信服务、业务使用、诈骗风险警示等信息。',
    '16.您有权自主选择携号转网服务，您办理携号转网业务应当符合工业和信息化部携号转网有关文件的规定条件和程序。',
    '17.办理携号转网业务过程中，您同意我方将必要的您身份信息提供给工业和信息化部集中业务管理系统（CSMS系统）， 用于比对携入方和携出方登记的身份信息是否一致。',
    '三、我方的权利和义务',
    '1.我方在其通信网络与设施覆盖范围内，向您提供通信服务； 其中，我方在签订有自动漫游协议的国家和地区的运营商的网络覆盖范围 内为您提供国际移动通信漫游服务及港澳台地区移动通信漫游服务。 我方提供的通信服务应符合国家规定的通信服务质量标准。',
    '2.我方免费向您提供中华人民共和国境内 （为本协议目的，仅限大陆地区，不含港澳台） 火警、匪警、医疗急救、交通事故报警等紧急呼叫服务。',
    '3.我方根据生产经营成本、电信市场供求状况等因素，合理确定并公示电信业务资费方案。',
    '4.我方可以通过营业网点、网站、电话、广播、短信、彩信、电子邮件、电视、 公开张贴、信函、报纸、互联网媒体等一种或数种方式，公布、 通知或告知业务种类和服务项目、范围、时限、资费方案以及其他通信服务内容。',
    '5.我方可以通过电话热线、营业网点、网上营业厅、 掌上营业厅或短信等多种渠道为您提供业务办理、咨询、查询、障碍申告、投诉受理等服务。',
    '6.我方负责网络接入保障、提供相关设备的安装、调测和维护服务，具体服务内容及费用等详见相关业务说明。',
    '7.您向我方申告除网络覆盖和终端设备故障外的通信服务障碍， 我方应当自接到申告之日起，城镇48小时、农村72小时内修复或调通。',
    '8.我方因检修线路、设备搬迁、工程施工、网络建设等原因可能影响正常通信服务的， 应当提前72小时向社会公告，通告方式包括但不限于网站、广播、短信、电视、公开张贴、报纸、互联网媒体等。',
    '9.我方向您提供免费的电子账单服务，并有义务对账单进行解释。 我方对您计费原始数据保留期限为5个月（系统产生用户话单当月起后5个月，不含当月）， 法律另有规定除外。如数据保留期限内您对通信费用提出异议， 我方应当保存相应计费原始数据至异议解决。',
    '10.我方可以根据对您实际情况的综合评估，与您约定一定时间内的电信消费透支额度（“信用额度”）并可以进行周期性调整。',
    '11.我方有权根据具体业务类型按预付方式或后付方式向您收取通信费用：',
    '（1）预付方式下，您需预存费用。当账户余额加上信用额度（如有）不足以支付您拟使用的电信业务费用时， 您应当及时充值或交费，否则我方可暂停提供通信服务（“欠费停机”）。',
    '（2）后付方式下，您累计未交的费用到达信用额度、或到达交费期时， 您应当及时充值或交费，否则我方可暂停提供通信服务。 其中，您当月累计出账费用的交费期以中国电信各省分公司业务规定为准。',
    '（3）上述（1）、（2）欠费停机所涉业务，不包括单独的固定电话业务。 固定电话业务的欠费停机时间按照《中华人民共和国电信条例》的相关规定执行。',
    '12.您自欠费停机之日起60日内结清费用并申请复机的， 我方应当在收到您费用时起24小时内为您恢复暂停的通信服务（“复机”）。',
    '13.如您自欠费停机之日起满60 日仍未结清欠费和违约金， 我方有权自欠费停机第61日起终止提供通信服务，收回相应号码（“销户”）、 解除本协议，以及通过信函、电话、诉讼或委托第三方等形式追缴欠费和违约金， 并可以依法向征信机构提供您失信信息。',
    '14.为提供更为精准优质的服务，我方严格按照《网络安全法》、 《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号） 等法律法规的相关要求，在业务活动（包括但不限于业务受理系统登记、 纸质返档，通过网络接收、读取并记录）中收集、使用您个人信息， 我方可以依法对包含您在内的整体用户数据进行分析并加以利用。 我方承诺对您个人信息依法负有保密义务，不得泄露、篡改或毁损，不得非法出售或非法向他人提供， 并应用行业通行的安全技术和管理手段（如脱敏展示、访问权限等）防止未经授权的访问和使用。',
    '15.为遵守相关法律法规或者行政机关、 监管机构等有权部门依法提出的要求以及基于诉讼 / 仲裁需要，我方有权披露您的个人信息。',
    '四、特殊情况的责任承担',
    '1.您入网后，应当妥善保管原始票据（如押金票据等），发生遗失的，由您自行承担责任。',
    '2.您密码是您办理业务的重要凭证。您入网后应当立即设置您密码，并妥善保管。为方便办理业务， 我方也可以根据您申请以短信等方式提供随机密码，该密码可以作为办理业务的临时凭证。',
    '3.凡使用您密码或随机密码定制、变更或取消业务的行为，均被视为您或您授权的行为。 因您对密码保管不善造成的损失由您自行承担，但我方有义务协助您或公安机关调查相关情况。',
    '4.您应当合理管理通信费用支出，出现异常的高额通信费用时（指超过您此前3个月平均通信费用5倍以上的费用）， 我方一经发现，应当尽可能迅速告知您，如告知后未得到您确认的，我方有权暂停本协议约定的部分或全部服务。',
    '5.在您欠费停机的情况下，我方有权拒绝为您办理除交费、通信费用查询外的其他电信业务。',
    '6.一方违约给对方造成直接损失的，应当依法承担赔偿责任。',
    '7.因不可抗力而使本协议部分或全部不能履行的，部分或全部免除责任， 但法律另有规定的除外。不可抗力指不能预见、不能避免并不能克服的客观情况。',
    '8.您使用本协议项下通信服务过程中涉及的第三方服务（如第三方支付、银行转账、与第三方应用绑定等）， 由第三方承担责任。对于您提出的异议，我方可以协调第三方解决。',
    '五、协议的生效、变更、终止和解除',
    '1.除本协议另有约定外，本协议自您签字（您为自然人）或盖章（您为非自然人）、 且我方盖章之日起生效；通过电子渠道、电子签名、电子协议或其他电子数据形式签订本协议的， 您以点击确认等方式表示同意本协议的电子数据进入我方系统的时间视为您签字时间。 如您为办理号码携入并签订本协议的， 甲、乙双方签字或盖章后，本协议于我方收到 携号转网成功生效结果告知之时起生效。',
    '2.您如终止使用通信服务，应当结清通信费用及违约金（如有）， 并至我方营业网点申请销户或号码携出，销户或号码携出后本协议终止。',
    '3.未经双方同意并办理有关手续，甲、我方不得将依据本协议办理的业务全部或部分转让给第三方。',
    '4.您申请销户或号码携出时账户仍有余额的， 我方根据实际情况将余额一次性转入您指定的我方运营的其他有效号码的账户中， 或以中国电信充值卡等方式退还；您通过参与赠送、优惠、兑换等营销活动获取的余额， 不予退还，如按营销活动的业务规则另有约定的，按其业务规则处理。',
    '5.因我方电信业务经营许可证的业务范围、业务种类发生改变，或因技术进步、 国家政策变动等原因，我方无法向您继续提供原有业务或需对原有业务的服务方式、 服务功能、操作方法、业务号码等进行调整时， 我方有权变更或终止本协议，但应当提前发布公告，提出解决方案，做好客户服务。',
    '6.有下列情形之一的，我方有权单方终止或解除协议：',
    '（1）您未按照用户真实身份信息登记要求， 提供的登记资料不真实、不准确、不完整、无效，或经我方通知后未在合理期限内配合提供的；',
    '（2）您存在本协议第二条第11款、第12款所列任一行为，或由此引起用户投诉或举报的；',
    '（3）您以担保方式取得业务使用权的情况下，违反担保约定或担保人无能力履行担保义务的；',
    '（4）您未办理相关手续，自行改变通信服务使用性质、用途，或二次转售、倒卖电话卡，或自行转让协议的；',
    '（5）您利用我方服务从事相应活动，依法应当取得而未取得或丧失相应许可或备案；',
    '（6）您自欠费停机之日起60日内仍未结清所有欠费、违约金、赔偿金等费用的；',
    '（7）有权机关要求我方停止对您提供通信服务的；',
    '（8）您原因造成本协议无法履行的；',
    '（9）法律、法规、规章及规范性文件规定的其他情形。',
    '7.本协议终止或解除后，我方继续保留向您追交所欠费用的权利， 并有权收回您原使用的业务号码（销户的号码）、IP地址等电信资源。 为保证您权益，我方对销户的业务号码至少冻结90日，方可重新分配给其他用户使用。',
    '8.您改号、销户或过户时应当主动注销或解绑在该号码上的注册或绑定的第三方应用 （如微信、支付宝）、银行卡（如银行验证号码）等信息、以及第三方软件，自行解除银行托收、 代扣等第三方协议，自行卸载您加载的第三方软件，避免造成个人信息泄露； 否则由此造成您或其他人损失由您承担。',
    '六、其他约定',
    '1.所有因本协议引起的或与本协议有关的争议， 双方将通过友好协商解决。协商不成的，您可向我方所在地有管辖权的人民法院提起诉讼。',
    '2.如果本合同的任何条款在任何时候变成不合法、 无效或不可强制执行但不从根本上影响本合同的效力时，本合同的其他条款不受影响。',
    '3.您办理各类业务所签署的业务登记单为本协议的一部分，业务登记单与本协议条款冲突的部分以业务登记单为准。 我方以公告等书面形式公开做出的服务承诺为本协议的补充，该等服务承诺与本协议冲突的部分以服务承诺为准。',
    '（您方承诺：本人已经充分、完整阅读并理解本协议所述全部条款及条件。）',
  ],
};

let noticeYDProtocol = {
  title: '关于客户个人信息收集，使用及保护的公告',
  content: [
    '尊敬的客户：',
    '您好！',
    '       根据《中华人民共和国个人信息保护法》、《中华人民共和国数据安全法》、《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理电话卡（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名、证件类型、证件号码等。同时，为更好地提供服务，可能需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。您在使用套餐类业务办理、通用流量包类业务办理、定向流量包类业务办理、权益+流量包办理、语音短信这类业务办理服务时，我们可能需要收集和使用您的手机号码、短信验证码，确保成功为您办理业务。',
    '上述数据会提供给我们的合作运营商，用于为您办理业务。在接受和使用我司销售分公司的服务和产品前，您应了解我司销售分公司的《隐私和信息保护政策》，（完整政策可查看网页https://dev.coc.10086.cn/coc/web2/Agreement/）同意本文件即视为您已了解并同意我司销售分公司的隐私和信息保护政策。',
    '       客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，中国移动有权不提供服务或终止服务。',
    '       为向客户提供优质、个性化的服务，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：',
    '1）事先获得客户的明确授权；',
    '2）根据有关的法律法规要求；',
    '3）按照相关司法机关和/或政府主管部门的要求；',
    '4）为维护社会公众的利益所必需且适当；',
    '5）为维护我公司或客户的合法权益所必需且适当；',
    '6）为确保您能成功办理中国移动独立或与我们合作的运营活动，我们会将您提交的信息提供给中国移动。',
    '',
    '',
    '河南天诺信息技术有限公司',
  ],
};

let internetProtocol = {
  title: '入网协议',
  content: ['尊敬的客户：', '您好！'],
};
let userInfoProtocol = {
  title: '关于客户个人信息收集、使用规则的公告',
  content: [
    '尊敬的客户：',
    '根据《中华人民共和国反恐怖主义法》《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》(工业和信息化部令第24号)和《电话用户真实身份信息登记规定》(工业和信息化部令第25号)等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点(含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等)办理固定电话、移动电话(含无线上网卡)入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息，根据业务受理情况，必要时会致电客户本人，客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。',
    '如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。',
    '为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息:',
    '(a)事先获得客户的明确授权;',
    '(b)根据有关的法律法规要求；',
    '(c)按照相关司法机关和/或政府主管部门的要求；',
    '(d)为维护社会公众的利益所必需且适当；',
    '(e)为维护我公司或客户的合法权益所必需且适当。',
  ],
};

let phoneProtocol = {
  title: '办理电话号码开户法律风险及防范提示告知书',
  content: [
    '为进一步加强打击治理通讯网络新型违法犯罪工作，根据《中华人民共和国刑法》、《中华人民共和国网络安全法》、《中华人民共和国电信条例》、《关于防范和打击电信网络诈骗犯罪的通告》、《工业和信息化部关于进一步防范和打击通讯信息诈骗工作的实施意见》等文件相关规定现对办理电话号码开户人法律风险及防范提示，告知如下：',
    '一、办理固定电话、移动电话等开户入网手续，必须持本人合法有效身份证件实名登记;不得冒用他人身份证件，或者使用伪造、变造的身份证件或单位证件办理入网手续。',
    '二、不得将办理的电话用于违法犯罪活动;不得转售、倒卖、出租电话卡。',
    '三、未取得电信业务经营许可证的任何组织或者个人，不得从事电信业务经营活动。',
    '请开户申请人严格遵守以上规定，如有违反，通信运营企业有权采取关停号码、解除合同、纳入信用管理等处置措施。对涉嫌伪造变造身份证件、帮助信息网络犯罪活动、非法经营等违法犯罪的，将依法严厉追究当事人法律责任。',
    '开户申请人已清楚明确阅读上述告知内容，将依法合规申请、使用相关电信业务。',
    '客户签名: 日期(年月日)',
  ],
};
