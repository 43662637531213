import React from 'react';
interface Props {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  navPage?: boolean;
  appBar?: React.ReactNode;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
}
export default function Page({ className, navPage, appBar, contentClassName, style, contentStyle, children }: Props) {
  return (
    <div className={`page ${navPage ? 'nav-page' : ''} ${className}`} style={style}>
      {appBar}
      <div className={contentClassName} style={contentStyle}>
        {children}
      </div>
    </div>
  );
}
