import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiClientGetOrderSn, apiSavePhoto, apiUploadPic } from '../api';
import MessageDialog from '../components/messageDialog';
import Page from '../components/page';
import { showToast } from '../utils/utils';

export default function CertificatesPage() {
  const location = useLocation();
  const [code, setCode] = useState<string>('');
  const [orderSn, setOrderSn] = useState<string>('');
  // 身份证头像照片
  const [cardAvatar, setCardAvatar] = useState<string>();
  // 身份证国徽照片
  const [cardNationalEmblem, setNationalEmblem] = useState<string>();
  // 手持身份证照片
  const [handPhoto, setHandPhoto] = useState<string>();
  // 接口报错msg
  const [messageDialogShow, setmessageDialogShow] = useState<string>('');
  // 接口报错msg
  const [message, setmessage] = useState<string>('');
  useEffect(() => {
    let _code = location.search ? location.search.split('?')[1] : '';
    if (_code) {
      setCode(_code);
      // 获取订单编码
      getClientGetOrderSn(_code);
    }
  }, []); // eslint-disable-line
  const getClientGetOrderSn = async (code: string) => {
    try {
      const res = await apiClientGetOrderSn(code);
      if (res.data.code === 200) {
        let data = res.data.data;
        setOrderSn(data);
      } else {
        setmessageDialogShow(res.data.msg ?? '服务未找到');
        setmessage(res.data.msg ?? '服务未找到');
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const chooseImg = async (t: FileList | null, type: string) => {
    if (t && t[0]) {
      if (t[0].size / 1024 / 1024 > 1.5) return showToast('照片不可以上传超过1.5MB');
      const res = await apiUploadPic(t[0], code);
      if (res) {
        if (type === 'cardAvatar') {
          setCardAvatar(res);
        } else if (type === 'cardNationalEmblem') {
          setNationalEmblem(res);
        } else if (type === 'handPhoto') {
          setHandPhoto(res);
        }
      }
    }
  };
  const handleSubmit = async () => {
    if (!orderSn) {
      return showToast('未查询到有效订单编码');
    }
    if (!cardAvatar) {
      return showToast('请上传身份证人像面');
    }
    if (!cardNationalEmblem) {
      return showToast('请上传身份证国徽面');
    }
    if (!handPhoto) {
      return showToast('请上传免冠人像照');
    }
    try {
      const res = await apiSavePhoto({
        frontPhoto: cardAvatar,
        backPhoto: cardNationalEmblem,
        handPhoto: handPhoto,
        randomNum: code,
      });
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '操作成功');
        clearImg();
      } else {
        showToast(data.msg ?? '操作失败');
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const clearImg = () => {
    setCardAvatar(undefined);
    setNationalEmblem(undefined);
    setHandPhoto(undefined);
  };
  return (
    <Page className="certificates-page" contentClassName="bh-100 p-20">
      <div className="d-flex-c ai-center">
        <div className="font-size-20 text-color-blue font-widget-600 border-bottom pb-10 pl-10 pr-10">
          实名制照片补充上传
        </div>
        <div className="font-size-12 text-color-blue line-height-26 mt-10 mb-5 text-indent-30">
          应工信部线上办理运营商业务实名制的要求，办理过程中需要您提供身份证信息以完成实名开卡，您的身份证信息及相关资料将严格保密并交由运营商保存。
        </div>
      </div>
      <div className="mt-15 mb-10 font-size-16">您的订单编号：</div>
      <div className="mt-10 border border-radius-5 p-10 pl-15 text-color-9">{orderSn}</div>
      <div className="mt-15 mb-10 font-size-16">照片上传：</div>
      <div className="border border-radius-5 d-flex-r ai-center jc-center bw-100 upload-box">
        {cardAvatar ? (
          <div className="upload-image bh-100 overflow-hidden">
            <img className="bw-100 bh-100" src={cardAvatar} alt="" />
            <div className="icon-close-block d-flex-r ai-center jc-center" onClick={() => setCardAvatar(undefined)}>
              <img className="icon-close-img" src="/pic/icon_close.png" alt="" />
            </div>
          </div>
        ) : (
          <div className="upload-pic text-center d-flex-c">
            <img className="upload-img" src="/pic/upload-img1.png" alt="" />
            <span className="mt-10 text-color-9">请上传身份证人像面</span>
            <input
              className="upload-input bw-100 bh-100"
              type="file"
              accept=".jpg,.png,.jpeg"
              onChange={(e) => {
                chooseImg(e.target.files, 'cardAvatar');
              }}
            />
          </div>
        )}
      </div>
      <div className="mt-20 border border-radius-5 d-flex-r ai-center jc-center bw-100 upload-box">
        {cardNationalEmblem ? (
          <div className="upload-image bh-100 overflow-hidden">
            <img className="bw-100 bh-100" src={cardNationalEmblem} alt="" />
            <div className="icon-close-block d-flex-r ai-center jc-center" onClick={() => setNationalEmblem(undefined)}>
              <img className="icon-close-img" src="/pic/icon_close.png" alt="" />
            </div>
          </div>
        ) : (
          <div className="upload-pic text-center d-flex-c">
            <img className="upload-img" src="/pic/upload-img2.png" alt="" />
            <span className="mt-10 text-color-9">请上传身份证国徽面</span>
            <input
              className="upload-input bw-100 bh-100"
              type="file"
              accept=".jpg,.png,.jpeg"
              onChange={(e) => {
                chooseImg(e.target.files, 'cardNationalEmblem');
              }}
            />
          </div>
        )}
      </div>
      <div className="mt-20 border border-radius-5 d-flex-r ai-center jc-center bw-100 upload-box pos-r">
        {handPhoto ? (
          <div className="upload-image bh-100 overflow-hidden">
            <img className="bw-100 bh-100" src={handPhoto} alt="" />
            <div className="icon-close-block d-flex-r ai-center jc-center" onClick={() => setHandPhoto(undefined)}>
              <img className="icon-close-img" src="/pic/icon_close.png" alt="" />
            </div>
          </div>
        ) : (
          <div className="upload-pic text-center d-flex-c">
            <img className="upload-img hand-photo" src="/pic/upload-img3.png" alt="" />
            <span className="mt-10 text-color-9">请上传免冠人像照</span>
            <input
              className="upload-input bw-100 bh-100"
              type="file"
              accept=".jpg,.png,.jpeg"
              onChange={(e) => {
                chooseImg(e.target.files, 'handPhoto');
              }}
            />
          </div>
        )}
      </div>
      {!message ? (
        <div
          className="mt-20 mb-20 d-flex-r al-center jc-center p-10 bw-100 border-radius-4 bg-blue text-color-white"
          onClick={handleSubmit}
        >
          提交上传
        </div>
      ) : (
        <div className="mt-20 mb-20 d-flex-r al-center jc-center p-10 bw-100 border-radius-4 bg-gray">{message}</div>
      )}

      <div className="mt-20 mb-20 text-color-red font-size-12 line-height-20">
        注：单个照片不能超过1.5MB，运营商实名开卡审核比较严格，为了提高照片的通过率，尽快给您发货，请严格按照模板要求拍摄。
      </div>
      <img className="bw-100" src="/pic/example-img.png" alt="" />
      {/* 接口500 返回的msg弹窗 */}
      {!!messageDialogShow && (
        <MessageDialog title="温馨提示" message={messageDialogShow} onClose={() => setmessageDialogShow('')} />
      )}
    </Page>
  );
}
