import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Goods } from '../../types';
interface Props {
  goods: Goods;
}
export default function GoodsItem({ goods }: Props) {
  const navigate = useNavigate();
  return (
    <div
      className="goods-item bg-white box-shadow border-radius-8 mb-10"
      onClick={() => navigate('/product?code=' + goods.goodsCode)}
    >
      <img className="goods-img" src={goods.goodsHeadImg} alt="" />
      <div className="m-10">{goods.goodsName}</div>
      <div className="d-flex-r jc-between ai-center pl-10 pr-10 pb-10">
        <div className="text-color-red font-widget-600">
          <span className="font-size-12">￥</span>
          <span className="font-size-16">{goods.paySwitch === 'Y' ? goods.goodsPrice : 0}</span>
        </div>
        <div className="text-color-9 font-size-12">销量{goods.goodsSales ?? 0}</div>
      </div>
    </div>
  );
}
