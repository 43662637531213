import { SelectOption } from '../../types';

interface Props {
  className?: string;
  options: SelectOption[];
  value: SelectOption;
  onChange: (value: SelectOption) => void;
}
export default function TabBar({ className, options, value, onChange }: Props) {
  return (
    <div className={`tab-bar-block ${className}`}>
      {options.map((i) => (
        <div
          key={i.value}
          className={`tab-bar-item ${i.value === value.value ? 'active' : ''}`}
          onClick={() => onChange(i)}
        >
          <div className="label">{i.label}</div>
          <div className="active-line" />
        </div>
      ))}
    </div>
  );
}
